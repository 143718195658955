import { Divider, HStack, Heading, StackProps, Text, VStack } from '@chakra-ui/react';
import { DEFAULT_UTC_TIMEZONE } from '@jurnee/common/src/entities/Address';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { getCityAndCountry } from '@jurnee/common/src/utils/addresses';
import { formatDate } from '@jurnee/common/src/utils/dates';
import { useTranslation } from 'react-i18next';

interface Props {
  partnerRequest: PartnerRequestJSON;
}

export function Details({ partnerRequest, ...inheritedProps }: Props & StackProps) {
  const { t } = useTranslation(['request', 'common']);

  const from = formatDate(
    new Date(partnerRequest.propositionsGroup.from),
    {
      displayTime: true,
      displayTimeZone: true,
      timeZone: partnerRequest.propositionsGroup.address?.timezone ?? DEFAULT_UTC_TIMEZONE
    }
  );

  return (
    <VStack spacing={2} alignItems="flex-start" {...inheritedProps}>
      <Heading size="md">{t('details.title')}</Heading>

      <VStack w="100%" spacing={3} p={4} bg="white" border="1px solid" borderColor="gray.200" borderRadius={4} alignItems="flex-start">
        <VStack w="100%" spacing={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700}>
              { t('fields.eventDate') }
            </Text>

            <Text color="gray.400">
              { from }
            </Text>
          </HStack>

          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700}>
              { t('fields.location') }
            </Text>

            <Text color="gray.400">
              { partnerRequest.propositionsGroup.address ? getCityAndCountry(partnerRequest.propositionsGroup.address) : '-' }
            </Text>
          </HStack>

          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={700}>
              { t('fields.participants') }
            </Text>

            <Text color="gray.400">
              { partnerRequest.propositionsGroup.participants } { t('common:people') }
            </Text>
          </HStack>
        </VStack>

        <Divider />

        <Text whiteSpace="pre-line">
          { partnerRequest.content || partnerRequest.propositionsGroup.content }
        </Text>
      </VStack>
    </VStack>
  );
}