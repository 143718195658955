import { HStack, Heading, VStack, useToast } from '@chakra-ui/react';
import { Loader } from '@jurnee/common/src/components/Loader';
import { BackButton } from '@jurnee/common/src/components/buttons/BackButton';
import { SecondaryButton } from '@jurnee/common/src/components/buttons/SecondaryButton';
import { PartnerRequestJSON } from '@jurnee/common/src/entities/PartnerRequest';
import { DeclineModal } from '@jurnee/common/src/modals/DeclineModal';
import { isPropositionsGroupDraft } from '@jurnee/common/src/utils/propositionsGroups';
import { getErrorToast, getSuccessToast } from '@jurnee/common/src/utils/toasts';
import { getPartnerRequest } from 'api/partnersRequests';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, Params, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store';
import { getPartnerSelector } from 'store/partner/partner.selectors';
import { declinePartnerRequestThunk } from 'store/partnerRequests/partnerRequests.thunks';
import { Comments } from './Comments';
import { Details } from './Details';
import { Propositions } from './Propositions';

export function Request() {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const params = useParams<Params<'partnerRequestId'>>();
  const partnerRequestId = Number(params.partnerRequestId);
  const { t } = useTranslation(['request', 'common']);

  const partner = useSelector(getPartnerSelector);
  const [partnerRequest, setPartnerRequest] = useState<PartnerRequestJSON>(null);
  const [isPartnerRequestLoading, setIsPartnerRequestLoading] = useState(true);

  async function fetchPartnerRequest() {
    try {
      const partnerRequest = await getPartnerRequest({ partnerId: partner.id, partnerRequestId });
      setPartnerRequest(partnerRequest);
      setIsPartnerRequestLoading(false);
    } catch(err) {
      toast(getErrorToast(t('toasts.fetchPartnerRequest.error')));
    }
  }

  useEffect(() => {
    fetchPartnerRequest();
  }, [partnerRequestId]);

  async function onDecline(partnerRequest: PartnerRequestJSON, declinedReason: string) {
    try {
      const declinedPartnerRequest = await dispatch(declinePartnerRequestThunk({
        partnerId: partner.id,
        partnerRequestId: partnerRequest.id,
        data: { declinedReason }
      })).unwrap();

      setPartnerRequest(declinedPartnerRequest);

      toast(getSuccessToast(t('toasts.declinePartnerRequest.success')));
    } catch (err) {
      toast(getErrorToast(t('toasts.declinePartnerRequest.error')));
    }
  }

  if (isPartnerRequestLoading) {
    return <Loader />;
  }

  const isEditable = isPropositionsGroupDraft(partnerRequest.propositionsGroup) && !partnerRequest.declinedAt;

  return (
    <VStack w="100%" h="100%" spacing={0}>
      <HStack w="100%" p={8} bg="white" borderBottom="1px solid" borderColor="gray.200" justifyContent="space-between">
        <HStack spacing={4}>
          <Link to="/requests">
            <BackButton />
          </Link>

          <Heading lineHeight="32px">{t('heading')} #{partnerRequest.id}</Heading>
        </HStack>

        {
          isEditable &&
          <DeclineModal
            title={t('modals.decline.title')}
            message={t('modals.decline.message')}
            reasonLabel={t('modals.decline.reason.label')}
            reasonPlaceholder={t('modals.decline.reason.placeholder')}
            button={t('common:buttons.decline')}
            onDecline={reason => onDecline(partnerRequest, reason)}
          >
            <SecondaryButton size="sm" colorScheme="pink">
              { t('common:buttons.decline') }
            </SecondaryButton>
          </DeclineModal>
        }
      </HStack>

      <HStack w="100%" maxW="1184px" alignSelf="center" p={8} spacing={8} alignItems="flex-start">
        <Details w="45%" minW={340} partnerRequest={partnerRequest} />

        <VStack w="55%" minW={400} spacing={6}>
          <Propositions
            partnerRequest={partnerRequest}
            isEditable={isEditable}
          />

          <Comments partnerRequestId={partnerRequest.id} />
        </VStack>
      </HStack>
    </VStack>
  );
}